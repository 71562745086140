/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { notification, Spin, Tabs } from 'antd'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
// import phoneMockup from '../../images/phone_mockup_marketting.png'
import { adminServices } from '../../services'
import { MobileOutlined, DesktopOutlined } from '@ant-design/icons'
import './Marketting.css' // Make sure to import the CSS file

const Marketting = () => {
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [previewMode, setPreviewMode] = useState('desktop') // 'desktop' or 'mobile'

  const handleSend = async () => {
    // Validate required fields
    if (!subject.trim() || !message.trim()) {
      notification.error({
        message: 'Validation Error',
        description: 'Subject and message are required fields'
      })
      return
    }

    setLoading(true)
    try {
      const response = await adminServices.adminSendNewsletterMessage({
        subject: subject.trim(),
        message: message.trim()
      })

      // Check if response exists and has data
      if (response?.data) {
        const { success } = response.data
        if (success) {
          notification.success({
            message: 'Success',
            description: 'Newsletter sent successfully'
          })
          // Reset form after successful send
          setSubject('')
          setMessage('')
        } else {
          notification.error({
            message: 'Error',
            description: 'Failed to send newsletter'
          })
        }
      } else {
        notification.error({
          message: 'Error',
          description: 'Invalid response from server'
        })
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred. Please try again.'

      if (error.response) {
        errorMessage = error.response.data?.message || error.response.data?.error || errorMessage
      } else if (error.request) {
        errorMessage = 'No response received from server. Please check your connection.'
      } else if (error.message) {
        errorMessage = error.message
      }

      console.error('Newsletter send error:', error)
      notification.error({
        message: 'Error Sending Newsletter',
        description: errorMessage
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 p-6'>
        <div className=''>
          {/* Subject Input */}
          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Subject</label>
            <input
              type='text'
              placeholder='Subject'
              value={subject}
              onChange={e => setSubject(e.target.value)}
              className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'
            />
          </div>

          {/* Message Area */}
          <div className='relative'>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Message</label>
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              className='w-full h-64 p-3 border border-gray-300 rounded-t-md focus:outline-none focus:border-blue-500'
              placeholder='Paste your HTML email content here...'
            />

            {/* Text Editor Controls - Disabled with Coming Soon badge */}
            <div className='relative'>
              <div className='absolute -top-3 right-4 z-10'>
                <span className='bg-yellow-400 text-xs font-semibold px-2.5 py-0.5 rounded-full'>
                  Coming Soon
                </span>
              </div>
              <div className='group relative'>
                <div
                  className='flex items-center gap-2 bg-bgBanner p-2 -mt-2 rounded-b-md border border-t-0 border-gray-300 opacity-60 cursor-not-allowed'
                  aria-disabled='true'
                >
                  {/* Attachment Icon */}
                  <button disabled className='p-2 rounded opacity-50'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-5 w-5'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'
                      />
                    </svg>
                  </button>

                  {/* Undo/Redo */}
                  <button disabled className='p-2 rounded opacity-50'>
                    ↩
                  </button>
                  <button disabled className='p-2 rounded opacity-50'>
                    ↪
                  </button>

                  {/* Font Controls */}
                  <select
                    disabled
                    value='Arial'
                    className='p-2 border rounded bg-gray-100 opacity-50'
                  >
                    <option>Font Family</option>

                    <option value='Arial'>Arial</option>
                    <option value='Times New Roman'>Times New Roman</option>
                  </select>

                  <select
                    disabled
                    value='12px'
                    className='p-2 border rounded bg-gray-100 opacity-50'
                  >
                    <option>Font size</option>
                    <option value='12px'>12</option>
                    <option value='14px'>14</option>
                    <option value='16px'>16</option>
                  </select>

                  {/* Text Formatting */}
                  <button disabled className='p-2 rounded opacity-50'>
                    B
                  </button>
                  <button disabled className='p-2 rounded opacity-50'>
                    I
                  </button>
                  <button disabled className='p-2 rounded opacity-50'>
                    U
                  </button>
                </div>

                {/* Tooltip */}
                <div className='hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-80 bg-gray-800 text-white text-sm rounded p-2'>
                  <div className='text-center'>
                    You can use HTML email editors like{' '}
                    <a
                      href='https://stripo.email/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-400 hover:text-blue-300'
                    >
                      Stripo
                    </a>{' '}
                    to design your email and paste the content here.
                  </div>
                  <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2'>
                    <div className='border-solid border-t-gray-800 border-t-8 border-x-transparent border-x-8 border-b-0' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Send Button - Updated with loading state */}
          <div className='mt-4'>
            <button
              onClick={handleSend}
              disabled={loading}
              className='px-6 py-2 bg-primary text-white rounded-md hover:bg-blue-700 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2'
            >
              {loading ? (
                <>
                  <Spin className='text-white' /> Sending...
                </>
              ) : (
                'Send'
              )}
            </button>
          </div>
        </div>

        {/* Preview Section - Updated */}
        <div className='bg-white rounded-lg shadow-lg'>
          <div className='p-4 border-b border-gray-200'>
            <div className='flex justify-between items-center'>
              <h2 className='text-lg font-semibold text-gray-700'>Preview</h2>
              <div className='flex gap-2 bg-gray-100 p-1 rounded-lg'>
                <button
                  onClick={() => setPreviewMode('desktop')}
                  className={`p-2 rounded-md transition-all ${
                    previewMode === 'desktop'
                      ? 'bg-white text-primary shadow-sm'
                      : 'text-gray-500 hover:bg-gray-200'
                  }`}
                >
                  <DesktopOutlined className='text-lg' />
                </button>
                <button
                  onClick={() => setPreviewMode('mobile')}
                  className={`p-2 rounded-md transition-all ${
                    previewMode === 'mobile'
                      ? 'bg-white text-primary shadow-sm'
                      : 'text-gray-500 hover:bg-gray-200'
                  }`}
                >
                  <MobileOutlined className='text-lg' />
                </button>
              </div>
            </div>
          </div>

          <div className='p-4 flex justify-center'>
            <div
              className={`preview-container ${
                previewMode === 'mobile' ? 'mobile-frame' : 'desktop-frame'
              }`}
            >
              {previewMode === 'mobile' && (
                <>
                  <div className='side-button volume-up' />
                  <div className='side-button volume-down' />
                  <div className='side-button power' />
                </>
              )}

              <div className='preview-content-wrapper'>
                {previewMode === 'mobile' && (
                  <div className='status-bar'>
                    <div className='status-bar-content'>
                      <span>9:41</span>
                    </div>
                    <div className='status-bar-content'>
                      <div className='status-icons'>
                        <div className='signal-icon'>
                          <div className='signal-bar' />
                          <div className='signal-bar' />
                          <div className='signal-bar' />
                          <div className='signal-bar' />
                        </div>
                        <div className='battery-icon'>
                          <div className='battery-level' />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Email Preview Header */}
                <div className='mb-4'>
                  <div className='text-sm' data-label='From:'>
                    Vittas
                  </div>
                  <div className='text-sm' data-label='To:'>
                    All Customers
                  </div>
                  <div className='text-lg'>{subject || 'No subject'}</div>
                </div>

                {/* Email Content Preview */}
                <div
                  className='preview-content prose max-w-none'
                  dangerouslySetInnerHTML={{
                    __html:
                      message || '<p class="text-gray-400">Email content will appear here...</p>'
                  }}
                />

                {previewMode === 'mobile' && <div className='home-indicator' />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Marketting
