export const MONO_KEY = process.env.REACT_APP_API_MONO_KEY
export const BASE_URL = process.env.REACT_APP_API_URL
export const UPLOAD_BASE_URL = process.env.REACT_APP_API_UPLOAD_URL
export const TRANSIT_PRIVATE_KEY = process.env.REACT_APP_TRANSIT_PRIVATE_KEY

export const ALL_ADMIN_ROUTES = [
  '/templates',
  '/admin-dashboard',
  '/admin-pending',
  '/admin-approved',
  '/admin-disbursed',
  '/admin-decline',
  '/admin-disbursed',
  '/system-logs',
  '/admin-customers',
  '/admin-roles',
  '/send-reminder',
  '/settings',
  '/administration'
]

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const IMAGES = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp']

export const FILE_EXTENSIONS = {
  IMAGES,
  VIDEOS: ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv'],
  DOCS: ['pdf', 'doc', 'docx', 'txt', 'ppt', 'pptx', ...IMAGES]
}

export const UPLOAD_FILE_TYPES = { IMAGES: 'IMAGES', VIDEOS: 'VIDEOS', DOCS: 'DOCS' }

export const DIRECT_DEBIT_PROVIDER = {
  PAYSTACK: 'paystack',
  FLICK: 'flicker'
}
