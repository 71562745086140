import React, { useState } from 'react'
import nurse from '../../../images/nurse.jpg'
import { notification, Spin } from 'antd'
import { LeadsApi } from '../../../services'
import { isValidEmail } from '../../../utils/inputValidator'

function CTASection() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleSubmit = async () => {
    const validEmail = isValidEmail(email)

    if (!validEmail) {
      notification.error({
        message: 'Invalid Email provided',
        description: 'Oops, looks like the email provided is not valid'
      })
      return
    }

    setLoading(true)
    try {
      await LeadsApi.subscribeToNewsletter({
        email
      })
      notification.success({
        message: 'Newsletter subscription',
        description: "Success! You're on the list. Stay tuned for exciting news!"
      })
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Something went wrong'
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className='bg-black text-white grid grid-cols-1 md:grid-cols-2 items-center  gap-8 w-full p-4 pt-32 md:p-16 '>
      <div>
        <p className='text-3xl md:text-6xl font-bold leading-8'>
          Your Gateway to Smarter Financing Solutions
        </p>
        <p className='my-6 text-xl'>
          Get the latest updates on innovative financing, loan management tips, and industry
          insights, straight to your inbox
        </p>

        <div>
          <div className='lg:w-4/12 lg:mr-4'>
            <div className='flex justify-center gap-4 flex-col'>
              <div className='relative'>
                <div className='absolute top-9 left-3'>
                  <svg width='24' height='24' fill='none' viewBox='0 0 24 24'>
                    <path
                      fill='#646464'
                      d='M17 21.25H7c-3.65 0-5.75-2.1-5.75-5.75v-7c0-3.65 2.1-5.75 5.75-5.75h10c3.65 0 5.75 2.1 5.75 5.75v7c0 3.65-2.1 5.75-5.75 5.75m-10-17c-2.86 0-4.25 1.39-4.25 4.25v7c0 2.86 1.39 4.25 4.25 4.25h10c2.86 0 4.25-1.39 4.25-4.25v-7c0-2.86-1.39-4.25-4.25-4.25z'
                    />
                    <path
                      fill='#646464'
                      d='M11.999 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 0 1 .93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79'
                    />
                  </svg>
                </div>
              </div>
              <input
                className='py-4   text-black px-5 pl-11 text-lg rounded-lg w-358px'
                placeholder='Enter your email address'
                type='email'
                onChange={e => setEmail(e?.target?.value)}
                value={email}
              />
              <button
                className='lg:bg-primary btn-primary-200 py-4 text-white font-bold text-xl mt-4 rounded-lg'
                onClick={handleSubmit}
              >
                {loading ? (
                  <>
                    <Spin className='text-white' /> Processing...
                  </>
                ) : (
                  'Subscribe'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={nurse} alt='nurse' />
      </div>
    </div>
  )
}

export default CTASection
